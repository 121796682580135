@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-13xl: 32px;
  --font-size-base: 16px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #1e1f26;
}
