.groupIcon,
.vectorIcon {
  height: 100%;
  bottom: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}
.groupIcon {
  width: 50%;
  right: 50%;
}
.allYourRoutes,
.group,
.groupIcon {
  position: absolute;
  top: 0;
  left: 0;
}
.group {
  height: 2.22%;
  width: 23.54%;
  top: 64.39%;
  right: 63.19%;
  bottom: 33.39%;
  left: 13.26%;
  color: var(--color-white);
}
.group1,
.group2,
.groupIcon1 {
  position: absolute;
  left: 62.78%;
}
.group1 {
  height: 4.33%;
  width: 7.22%;
  top: 28.28%;
  right: 30%;
  bottom: 67.39%;
  font-size: var(--font-size-13xl);
}
.group2,
.groupIcon1 {
  height: 2.22%;
  width: 5.83%;
  top: 35.17%;
  right: 31.39%;
  bottom: 62.61%;
}
.groupIcon1 {
  height: 5.78%;
  width: 24.72%;
  top: 39.89%;
  right: 12.64%;
  bottom: 54.33%;
  left: 62.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.group3 {
  position: absolute;
  height: 2.22%;
  width: 5.42%;
  top: 48.72%;
  right: 31.81%;
  bottom: 49.06%;
  left: 62.78%;
}
.groupIcon2,
.vectorIcon1 {
  position: absolute;
  height: 5.78%;
  width: 24.72%;
  top: 53.44%;
  right: 12.64%;
  bottom: 40.78%;
  left: 62.64%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon1 {
  height: 101.82%;
  width: 100.28%;
  top: -0.91%;
  right: -0.14%;
  bottom: -0.91%;
  left: -0.14%;
}
.logIn1 {
  position: absolute;
  top: 30.91%;
  left: 42.88%;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  color: #030303;
  text-align: left;
}
.loginButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 6.11%;
  width: 24.38%;
  top: 61.61%;
  right: 12.81%;
  bottom: 32.28%;
  left: 62.81%;
}
.group4,
.maskGroup {
  position: absolute;
  height: 52.7%;
  width: 84.74%;
  top: 49.32%;
  right: 8.68%;
  bottom: -2.03%;
  left: 6.58%;
}
.maskGroup {
  height: 8.22%;
  width: 26.39%;
  top: 54.11%;
  right: 61.81%;
  bottom: 37.67%;
  left: 11.81%;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
}
.groupIcon3 {
  position: absolute;
  height: 45.78%;
  width: 28.61%;
  top: 21.33%;
  right: 60.69%;
  bottom: 32.89%;
  left: 10.69%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.login1 {
  position: relative;
  width: 100%;
  height: 900px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-100);
  font-family: var(--font-montserrat);
}
